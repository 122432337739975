import React from 'react'
import css from './Client.module.css'

function Client() {
    return (
        <div className={css.container_1}>
            <h1>Client Page</h1>
        </div>
    )
}

export default Client
