import React, { createContext, useEffect, useState } from 'react';
import { BASE_URL, CUISINES_URL } from '../constants/urls';

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
    const [cuisines, setCuisines] = useState([]);

    const headers = {
        Authorization: localStorage.getItem("auth-token"),
        userId: localStorage.getItem("userId"),
        userRole: localStorage.getItem("userRole"),
        "Content-Type": "application/json"
    }

    async function logout() {
        fetch(`${BASE_URL}/crm/logout`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ userId: localStorage.getItem("userId") })
        })
        localStorage.removeItem("auth-token")
        localStorage.removeItem("userRole")
        localStorage.removeItem("userId")
    }

    useEffect(() => {
        if (cuisines.length === 0) {
            fetch(CUISINES_URL).then((res) => { res.json().then((res) => { setCuisines(res.data.cuisines); }) })
        }
        if (localStorage.getItem("auth-token")) {
            fetch(`${BASE_URL}/crm/getLeads`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ offset: 0, limit: 1, filters: { category: "chef" } }),
            })
                .then((res) => { if (!res.ok) { logout() } })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <MyContext.Provider value={{
            cuisines,
            headers,
            logout
        }}>
            {children}
        </MyContext.Provider>
    );
};
