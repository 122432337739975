import React, { useContext, useEffect, useState } from 'react'
import css from './LeadFormModal.module.css'
import CustomInput from './ui/CustomInput'
import CuisineSelector from './ui/CuisineSelector'
import { BASE_URL } from '../constants/urls'
import { MyContext } from '../context/AppContext'

function LeadFormModal() {

    const context = useContext(MyContext)

    const leadFormBasicDetails = [
        { label: 'Name', name: 'name' },
        { label: 'Contact Number', name: 'contactNumber' },
        { label: 'Email', name: 'email' },
        { label: 'City', name: 'city' },
        { label: 'State', name: 'state' }
    ]

    const leadFormProfessionalDetails = [
        { label: 'Experience', name: 'experience' },
        { label: 'Designation', name: 'designation' },
        { label: 'Qualification', name: 'qualification' },
        { label: 'Remarks', name: 'remarks' },
        { label: 'Shared For', name: 'sharedFor' }
    ]

    const initialLeadForm = {
        name: '', contactNumber: '', email: '', city: '', state: '', role: [], salary: '', experience: '', designation: '', qualification: '', remarks: '', cuisine: '', sharedFor: ''
    }

    const [isLoading, setIsLoading] = useState(false)
    const [clickedBtn, setClickedBtn] = useState(0)
    const [leadForm, setLeadForm] = useState(initialLeadForm);
    const [errors, setErrors] = useState({});
    const [selected, setSelected] = useState([])
    const userRole = localStorage.getItem("userRole");

    useEffect(() => {
        setLeadForm({ ...leadForm, 'cuisine': selected });
        // eslint-disable-next-line
    }, [selected])

    useEffect(() => {
        const keys = Object.keys(errors)
        if (keys.length) {
            document.querySelector(`[name="${keys[0]}"]`).scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [errors])

    function handleInputChange(e) {
        const { name, value } = e.target
        setLeadForm({ ...leadForm, [name]: value });
    }

    function handleRoleChange(e) {
        const { value, checked } = e.target;
        setLeadForm((prevLeadForm) => ({
            ...prevLeadForm,
            role: checked
                ? [...prevLeadForm.role, value]
                : prevLeadForm.role.filter((role) => role !== value),
        }));
    };

    function handleClearLead() {
        setLeadForm(initialLeadForm);
        setErrors({})
        setSelected([])
    }

    function validateLeadForm() {
        var temp = {}
        if (!leadForm.contactNumber) {
            temp['contactNumber'] = 'Contact number required'
        }
        else if (/^\+?\d{10}$/.test(leadForm?.contactNumber) === false) {
            temp['contactNumber'] = 'Invalid Contact Number'
        }
        if (leadForm.salary && (leadForm.salary < 0 || leadForm.salary > 1000000)) {
            temp['salary'] = 'Salary ranges from 0-10 lakh'
        }
        setErrors(temp);
        return Object.keys(temp).length === 0;
    }

    async function handleAddLead() {
        if (!validateLeadForm()) { return; }
        setErrors({});
        setIsLoading(true);
        setClickedBtn(2)
        const body = {
            name: leadForm.name || "",
            contact: leadForm.contactNumber || "",
            category: leadForm?.category || "chef",
            roles: [...(leadForm.role.includes('chef') ? leadForm.role : [...leadForm.role, 'chef'])],
            cuisines: leadForm.cuisine || [],
            salary: parseInt(leadForm.salary) || 1,
            city: leadForm.city || "",
            remarks: leadForm.remarks || "",
            state: leadForm.state || "",
            qualification: leadForm?.qualification || "",
            relocation: leadForm?.relocation || false,
            sharedFor: leadForm?.sharedFor || "",
            email: leadForm?.email || "",
            designation: leadForm?.designation || "",
            experience: leadForm?.experience || "",
            createdBy: localStorage.getItem("userId") || "",
        };
        try {
            const response = await fetch(`${BASE_URL}/crm/leads`, {
                method: "POST",
                headers: context.headers,
                body: JSON.stringify(body)
            });
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (data.success) {
                alert('Lead has been added')
            }
            else {
                alert(data.message)
            }
        }
        catch (error) {
            console.error("Error:", error);
        }
        handleClearLead()
        setIsLoading(false);
        setClickedBtn(0)
    };

    async function handleUpload(e) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(`${BASE_URL}/crm/uploadLeads`, {
                method: 'POST',
                headers: context.headers,
                body: formData
            });
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (response.ok) {
                alert("File has been uploaded");
            } else {
                const errorData = await response.json();
                alert(errorData.message || "File upload failed");
            }
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <div>
            <div className={css.lead_form_container}>
                <h2 className={css.sub_title_1}>Lead Form</h2>
                {
                    isLoading && clickedBtn === 2 ? <div className={css.filter_data_container}>Loading....</div> :
                        <>
                            <h3 className={css.sub_title_2}>Basic Details</h3>
                            <div className={css.lead_form}>
                                {leadFormBasicDetails.map((field, index) => (
                                    <div key={index} >
                                        <CustomInput
                                            name={field.name}
                                            label={field.label}
                                            value={leadForm[field.name]}
                                            error={errors[field.name]}
                                            formType={"LeadForm"}
                                            triggerFunction={handleInputChange}
                                        />
                                    </div>
                                ))}
                                <div className={css.input_group}>
                                    <label>Role</label>
                                    <div>
                                        <div className={css.custom_checkbox_container}>
                                            <input className={css.custom_checkbox} type="checkbox" value="party cook" onChange={handleRoleChange} id="partyCookCheckBox" />
                                            <label htmlFor="partyCookCheckBox">Party Cook</label>
                                        </div>
                                    </div>
                                    <span className={css.error_container}>{errors['role']}</span>
                                </div>
                                <div></div><div></div>
                            </div>
                            <h3 className={css.sub_title_2}>Professional Details</h3>
                            <div className={css.lead_form}>
                                <CustomInput
                                    name={'salary'}
                                    label={'Salary'}
                                    error={errors['salary']}
                                    formType={"LeadForm"}
                                    triggerFunction={handleInputChange}
                                    value={leadForm.salary}
                                />
                                {leadFormProfessionalDetails.map((field, index) => (
                                    <CustomInput
                                        key={index}
                                        name={field.name}
                                        label={field.label}
                                        value={leadForm[field.name]}
                                        error={errors[field.name]}
                                        formType={"LeadForm"}
                                        triggerFunction={handleInputChange}
                                    />
                                ))}
                                <CuisineSelector selected={selected} setSelected={setSelected} />
                            </div>
                        </>
                }
            </div>
            <div className={css.button_group}>
                <button className={`${css.clear_lead_btn} btn`} onClick={handleClearLead}>Clear Lead</button>
                <button className={`${css.add_lead_btn} btn`} onClick={handleAddLead}>Add Lead</button>
                {userRole === 'admin' && (
                    <>
                        <input type="file" name="upload" id="upload" accept=".csv" style={{ display: 'none' }} onChange={(e) => handleUpload(e)} />
                        <label htmlFor='upload' className='blue_btn btn'>
                            Upload
                        </label>
                    </>
                )}
            </div>
        </div>
    )
}

export default LeadFormModal