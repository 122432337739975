import React from 'react'
import css from './HouseCook.module.css'

function HouseCook() {
    return (
        <div className={css.container_1}>
            <h1>House Cook Page</h1>
        </div>
    )
}

export default HouseCook
