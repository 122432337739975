import React from 'react'
import css from './DashBoard.module.css'

function DashBoard() {
    document.title = 'CookandChef CRM | Dashboard'
    return (
        <>
            <div className={css.container_1}>
                <div className={css.container_3}>
                    <div className={css.container_4}>
                        <div>
                            Welcome, {localStorage.getItem("userName")}
                        </div>
                    </div>
                    <div className={css.container_5}>
                        <div>
                            <div>Todays Leads</div>
                            <div>15</div>
                        </div>
                        <div>
                            <div>Monthly Leads</div>
                            <div>365</div>
                        </div>
                    </div>
                    <div className={css.container_6}>
                        <div>card 1</div>
                        <div>card 2</div>
                        <div>card 3</div>
                        <div>card 4</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashBoard
