import React from "react";
import css from "./NavBar.module.css";
import { NavLink } from "react-router-dom";

const navItems = [
  { to: "/dashboard", label: "Dashboard" },
  { to: "/chef", label: "Chef" },
  { to: "/users", label: "Users", adminOnly: true },
  { to: "/client", label: "Client" },
  { to: "/houseCook", label: "House Cooks" },
  { to: "/tasks", label: "Tasks" },
  { to: "/employer", label: "Employer" },
  { to: "/settings", label: "Settings" },
];

const NavItem = ({ to, label }) => (
  <NavLink
    to={to}
    className={({ isActive }) => isActive ? `${css.nav_link} ${css.active}` : css.nav_link}
  >
    {label}
  </NavLink>
);

function NavBar() {
  const userRole = localStorage.getItem("userRole");

  return (
    <div className={css.container_1}>
      <div className={css.logo_container}></div>
      {navItems.map(
        (item) =>
          (!item.adminOnly || userRole === "admin") && (
            <NavItem key={item.to} to={item.to} label={item.label} />
          )
      )}
    </div>
  );
}

export default NavBar;
