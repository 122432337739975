import React, { useState } from 'react';
import css from './HeaderModal.module.css';
import { ReactComponent as UserIcon } from '../assets/user-icon.svg';
import { useNavigate } from 'react-router-dom';

const HeaderModal = () => {
    const [openUserModal, setOpenUserModal] = useState(false);
    
    const navigate=useNavigate()

    function handleIconClick() {
        setOpenUserModal(!openUserModal);
    }

    function handleLogout() {
        localStorage.removeItem("auth-token")
        localStorage.removeItem("userName")
        localStorage.removeItem("userRole")
        navigate('/')
        window.location.reload()
    }

    function handleBlur() {
        if (openUserModal) {setOpenUserModal(false);}
    }

    return (
        <div className={css.container_1} tabIndex={0} onBlur={handleBlur}>
            <UserIcon onClick={handleIconClick} style={{ cursor: "pointer" }} />
            {openUserModal && <div className={css.userModal}>
                <div onClick={handleLogout}>
                    Logout
                </div>
            </div>}
        </div>
    );
};

export default HeaderModal;
