import React, { useContext, useState } from 'react'
import css from './RemarksModal.module.css'
import CloseBtn from './ui/CloseBtn';
import { BASE_URL } from '../constants/urls';
import { MyContext } from '../context/AppContext';

function RemarksModal({ data, handelOpenRemarks, leadId }) {

    const context = useContext(MyContext)

    const [isLoading, setIsLoading] = useState(false)
    const [clickedBtn, setClickedBtn] = useState(-1)
    const [btnType, setBtnType] = useState('update')
    const [remark, setRemark] = useState({ remark: '', updatedBy: localStorage.getItem("userId") })
    const [openAddModule, setOpenAddModule] = useState(false)
    const [remarksState, setRemarksState] = useState(data?.map((field, idx) => ({
        id: field?.id || idx,
        remark: field?.remark || '',
        updatedBy: field?.updatedBy || 'myself',
        disabled: true
    })));

    const handelRemarkOnChange = (e, idx) => {
        const updatedRemarksState = [...remarksState];
        updatedRemarksState[idx] = {
            ...updatedRemarksState[idx],
            remark: e.target.value,
            disabled: false,
        };
        setRemarksState(updatedRemarksState);
    };

    const validateInput = (data, type) => {
        if (type === 'remark') {
            if (data === '') {
                alert("Please enter a remark")
                return false
            }
            return true
        }
        return true
    }

    const handelUpdate = async (idx) => {
        setIsLoading(true);
        setClickedBtn(idx)
        const body = { remark: remarksState[idx].remark, updatedBy: remarksState[idx].updatedBy }
        if (validateInput(remarksState) === false) {
            setIsLoading(false);
            return;
        }
        try {
            const response = await fetch(`${BASE_URL}/crm/leads/${leadId}/remarks/${remarksState[idx].id}`, {
                method: "PUT",
                headers: context.headers,
                body: JSON.stringify(body)
            });
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (data.success) {
                alert("Remark has been updated")
                refreshData()
            }
            else {
                alert(data.message)
            }
        }
        catch (error) { console.log(error); }
        setClickedBtn(-1)
        setIsLoading(false);
    };

    const handelDelete = async (idx) => {
        const a = window.confirm("Are you sure you want to delete this remark?")
        if (a) {
            setIsLoading(true);
            setClickedBtn(idx);
            setBtnType('delete')
            try {
                const response = await fetch(`${BASE_URL}/crm/leads/${leadId}/remarks/${remarksState[idx].id}`, {
                    method: "DELETE",
                    headers: context.headers
                });
                const data = await response.json()
                if (data.statusCode === 401) { context.logout(); return }    
                if (data.success) {
                    alert("Remark has been deleted")
                }
                else {
                    alert(data.message)
                }
            }
            catch (error) { console.log(error); }
            setBtnType('update')
            setClickedBtn(-1)
            setIsLoading(false);
            refreshData()
        }
    }

    const handleAdd = async () => {
        setIsLoading(true);
        const body = { remark: remark.remark, updatedBy: remark.updatedBy }
        if (validateInput(remark, 'remark') === false) {
            setIsLoading(false);
            return;
        }
        try {
            const response = await fetch(`${BASE_URL}/crm/leads/${leadId}/remarks`, {
                method: "POST",
                headers: context.headers,
                body: JSON.stringify(body)
            });
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (data.success) {
                alert("Remark has been added")
            }
            else {
                alert(data.message)
            }
        }
        catch (error) { alert(error.message); }
        setIsLoading(false);
        refreshData()
    }

    const refreshData = () => {
        const btn1 = document.getElementById("remark-close-btn");
        const btn2 = document.getElementById("lead-search-btn");
        if (btn1) { btn1.click(); }
        if (btn2) { btn2.click(); }
    };


    return (
        <div className={css.container_1}>
            <div className={css.container_5}>
                <div>
                    <h2 className={css.title}>Remarks</h2>
                    <button className={`btn ${openAddModule ? 'red_btn' : 'green_btn'}`} onClick={() => setOpenAddModule(!openAddModule)}>
                        {openAddModule ? "Close" : "Add Remark"}
                    </button>
                </div>
                <CloseBtn triggerFunction={handelOpenRemarks} id={"remark-close-btn"} />
            </div>
            <hr />
            <div className={css.container_4}>
                {
                    !openAddModule && data?.map((field, idx) => {
                        return (
                            <div key={idx} className={css.container_2}>
                                <div>
                                    <strong>Created By:</strong>{" "}
                                    {field?.updatedBy}{" "}
                                    ({new Date(field?.updatedAt).toLocaleString()})
                                </div>
                                <div className={css.container_3}>
                                    <div className={css.container_3_1}>
                                        <button
                                            className={`btn blue_btn`}
                                            disabled={(remarksState[idx]?.disabled) || (isLoading && clickedBtn === idx)}
                                            onClick={() => handelUpdate(idx)}>{
                                                isLoading && clickedBtn === idx && btnType === 'update' ? "Loading..." : "Update"
                                            }
                                        </button>
                                        <button
                                            className={`btn red_btn`}
                                            onClick={() => handelDelete(idx)}>{isLoading && clickedBtn === idx && btnType === 'delete' ? "Loading..." : "Delete"}
                                        </button>
                                    </div>
                                    <textarea
                                        name="remarks"
                                        defaultValue={field?.remark}
                                        onChange={(e) => handelRemarkOnChange(e, idx)
                                        }></textarea>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    openAddModule && (
                        <div className={css.container_2}>
                            <div className={css.container_3}>
                                <textarea name="remarks" onChange={(e) => setRemark({ ...remark, remark: e.target.value })} placeholder='Enter your remark here'>
                                </textarea>
                                <button className={`btn green_btn`} onClick={handleAdd}>Submit</button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default RemarksModal