import React from 'react'
import css from './Settings.module.css'

function Settings() {
    return (
        <div className={css.container_1}>
            <h1>Settings Page</h1>
        </div>
    )
}

export default Settings
