import React from 'react'
import css from './Employer.module.css'

function Employer() {
    return (
        <div className={css.container_1}>
            <h1>Employer Page</h1>
        </div>
    )
}

export default Employer
