import React from 'react';
import css from './CustomInput.module.css'

function CustomInput({ name, label, value, error, formType, triggerFunction }) {

    const handleInputType = (type) => {
        switch (type) {
            case 'Email':
                return 'email';
            case 'Contact Number':
                return 'tel';
            case 'Salary':
                return 'number';
            case 'Experience':
                return 'number'
            default:
                return 'text';
        }
    };

    const handleMin = (type) => {
        switch (type) {
            case 'Salary':
                return 0;
            case 'Experience':
                return 0;
            default:
                return 0;
        }
    }

    const handleMax = (type) => {
        switch (type) {
            case 'Salary':
                return 1000000;
            case 'Experience':
                return 50;
            default:
                return 1000000;
        }
    }

    return (
        <div className={css.inputGroup}>
            <label htmlFor={name}>{label}</label>
            <input
                id={name}
                name={name}
                value={value}
                type={handleInputType(label)}
                onChange={(e) => triggerFunction(e, formType)}
                className={css.inputField}
                min={handleMin(label)}
                max={handleMax(label)}
            />
            {error && <span className={css.errorContainer}>{error}</span>}
        </div>
    );
}

export default CustomInput;
