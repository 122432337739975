import React from 'react';
import css from './Pagination.module.css'

function Pagination({ triggerFunction, currentPage, totalPages }) {

    return (
        <div className={css.container}>
            <h3>Page Number</h3>
            <select
                onChange={(e) => triggerFunction(parseInt(e.target.value, 10))}
                value={currentPage + 1}
                name="pageNumber"
                id="pageNumber"
            >
                {Array.from({ length: totalPages }, (_, idx) => (
                    <option key={idx + 1} value={idx + 1}>
                        Page {idx + 1}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default Pagination;
