import React, { useContext, useEffect, useState } from 'react'
import css from './Users.module.css'
import CustomInput from '../components/ui/CustomInput'
import EditUserModal from '../components/EditUserModal';
import { BASE_URL } from '../constants/urls';
import { MyContext } from '../context/AppContext';
import UserResetPwModal from '../components/UserResetPwModal';
import Pagination from '../components/ui/Pagination';

function Users() {

    const context = useContext(MyContext)
    document.title = 'CookandChef CRM | Users'

    const [data, setData] = useState([])
    const [rowSelected, setRowSelected] = useState(-1)
    const [openActionModal, setOpenActionModal] = useState(-1)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openResetPwModal, setOpenResetPwModal] = useState(false)
    const [openCreateUserModal, setOpenCreateUserModal] = useState(false)
    const [userForm, setUserForm] = useState({
        userId: "",
        uname: '',
        access: 'manager',
        email: '',
        password: '',
        jobs: [],
        cooks: []
    })
    const [errors, setErrors] = useState({});
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const itemsPerPage = 25;
    const totalPages = Math.ceil(totalItems / itemsPerPage)


    const handleCreateUser = () => {
        setOpenCreateUserModal(!openCreateUserModal)
    }

    const handleAction = (idx) => {
        setOpenActionModal(idx)
        setRowSelected(idx)
    }

    const handleEdit = () => {
        setOpenActionModal(-1)
        setOpenEditModal(true)
    }

    const handleStatus = async (id, status) => {
        setOpenActionModal(-1)
        var flag = status === 'Active' ? false : true
        try {
            const response = await fetch(`${BASE_URL}/crm/users/${id}/login`, {
                method: "PUT",
                headers: context.headers,
                body: JSON.stringify({ enable: flag, updatedBy: context.headers.userId })
            })
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (response.ok) {
                alert(`User ${id} is set to ${flag ? "Active" : "Disabled"}`)
            }
            else {
                alert("Something went wrong")
            }
            window.location.reload()
        } catch (error) {
            alert(error)
        }
    }

    const handleResetPw = () => {
        setOpenActionModal(-1)
        setOpenResetPwModal(true)
    }

    const handleDelete = async (id) => {
        setOpenActionModal(-1)
        try {
            const a = window.confirm("Do you want to delete the user ?")
            if (a) {
                const response = await fetch(`${BASE_URL}/crm/users/${id}`, {
                    method: "DELETE",
                    headers: context.headers
                })
                const data = await response.json()
                if (data.statusCode === 401) { context.logout(); return }
                if (response.ok) {
                    alert(`User ${id} has been deleted`)
                }
                else {
                    alert("Something went wrong")
                }
                window.location.reload()
            }
            else return
        } catch (error) {
            alert(error)
        }
    }

    const handleReset = (e) => {
        e.preventDefault()
        setUserForm({
            userId: '',
            uname: '',
            access: '',
            email: '',
            password: '',
            jobs: [],
            cooks: []
        })
        setErrors({})
    }

    const validateInput = () => {
        let temp = {}
        if (!userForm.uname) {
            temp['uname'] = 'Enter user name'
        }

        if (!userForm.email) {
            temp['email'] = 'Enter email'
        }

        if (!userForm.password) {
            temp['password'] = 'Enter password'
        }

        if (userForm.jobs.length === 0 && userForm.cooks.length === 0) {
            temp['category'] = 'Select at least one from the categories'
        }

        if (!userForm.userId) {
            temp['userId'] = 'Enter user id'
        }

        setErrors(temp)
        return Object.keys(temp).length === 0;
    }

    const handleCreate = async (e) => {
        e.preventDefault()
        if (!validateInput()) { return; }
        const body = {
            "employeeId": userForm.userId,
            "name": userForm.uname,
            "email": userForm.email,
            "password": userForm.password,
            "role": userForm.access,
            "categories": [...userForm.cooks, ...userForm.jobs],
            "createdBy": "admin@gmail.com"
        }
        try {
            const response = await fetch(`${BASE_URL}/crm/users`, {
                method: "POST",
                body: JSON.stringify(body),
                headers: context.headers
            })
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (response.ok) {
                alert("User has been created")
                window.location.reload()
            }
        } catch (error) {
            alert(error)
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setErrors({ ...errors, [name]: '' })
        setUserForm({ ...userForm, [name]: value })
    }

    const handleJobChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setUserForm((userForm) => ({ ...userForm, jobs: [...userForm.jobs, value] }));
        } else {
            setUserForm((userForm) => ({ ...userForm, jobs: userForm.jobs.filter((job) => job !== value) }));
        }
    };

    const handleCookChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setUserForm((userForm) => ({ ...userForm, cooks: [...userForm.cooks, value] }));
        } else {
            setUserForm((userForm) => ({ ...userForm, cooks: userForm.cooks.filter((cook) => cook !== value) }));
        }
    }

    const getUsers = (idx) => {
        const offset = idx ? (idx - 1) * itemsPerPage : 0;
        if (idx) setCurrentPage(idx - 1);
        fetch(`${BASE_URL}/crm/getUsers`, {
            method: "POST",
            body: JSON.stringify({ "offset": offset, "limit": itemsPerPage }),
            headers: context.headers
        }).then((res) => {
            if (res.status === 401) { context.logout() }
            else {
                res.json().then((res) => {
                    const data = res.data.users;
                    setTotalItems(res.data.count);
                    var newData = []
                    data.forEach(e => {
                        var temp = {}
                        temp['id'] = e.employeeId
                        temp['name'] = e.name
                        temp['accessLevel'] = e.role === 'admin' ? 'manager' : e.role
                        temp['status'] = e.loginEnabled ? "Active" : "Disabled"
                        temp['email'] = e.email
                        temp['jobs'] = []
                        temp['cooks'] = []
                        e.categories.forEach(item => {
                            if (["employer", "jobs", "client", "requirements"].includes(item)) {
                                temp.jobs.push(item)
                            }
                            if (["chef", "houseCook", "partyCook", "catering"].includes(item)) {
                                temp.cooks.push(item)
                            }
                        });
                        newData.push(temp)
                    });
                    setData(newData)
                })
            }
        })
    }
    useEffect(() => {
        getUsers()
        // eslint-disable-next-line
    }, [])

    return (
        <div className={css.container_1}>
            <div>
                <h1>{openCreateUserModal ? "Create User" : "Users List"}</h1>
                {!openCreateUserModal && <Pagination triggerFunction={getUsers} currentPage={currentPage} totalPages={totalPages} />}
                <button onClick={handleCreateUser} className={`btn ${openCreateUserModal ? "red_btn" : "green_btn"}`}>{openCreateUserModal ? "Close" : "Create User"}</button>
            </div>
            <div>
                {
                    !openCreateUserModal && <table align='center' className={css.table}>
                        <thead>
                            <tr>
                                <th>User ID</th>
                                <th>Name</th>
                                <th>Access Level</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length === 0 && <div className={css.loader}>Loading...</div>}
                            {
                                data.map((item, idx) => (
                                    <tr
                                        key={idx}
                                        onMouseLeave={() => setOpenActionModal(false)}
                                    >
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.accessLevel}</td>
                                        <td
                                            className={`${item.status === 'Active' ? css.active : css.inactive}`}
                                        >
                                            <div>{item.status}</div>
                                        </td>
                                        <td>
                                            <button className={css.actionBtn} onClick={() => handleAction(idx)}>{'\u2807'}</button>
                                            {
                                                openActionModal === idx && (
                                                    <div className={css.actionModal}>
                                                        <button onClick={() => handleEdit()}>Edit</button>
                                                        <button onClick={() => handleStatus(item.id, item.status)}>{item.status === "Active" ? "Disable" : "Enable"}</button>
                                                        <button onClick={() => handleResetPw(item.id)}>Reset Password</button>
                                                        <button onClick={() => handleDelete(item.id)}>Delete</button>
                                                    </div>
                                                )
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                }
                {
                    openCreateUserModal && (
                        <div className={css.createModal}>
                            <form>
                                <div>
                                    <CustomInput
                                        name={'uname'}
                                        label={'Name'}
                                        value={userForm.uname}
                                        error={errors.uname}
                                        formType={'createUser'}
                                        triggerFunction={(e) => handleInputChange(e)}
                                    />
                                    <div>
                                        <label htmlFor="access"><h3>Access Level</h3></label>
                                        <select name="access" id="access" onChange={(e) => handleInputChange(e)}>
                                            <option value="manager">Manager</option>
                                            <option value="executive">Executive</option>
                                            <option value="associate">Associate</option>
                                        </select>
                                    </div>
                                    <CustomInput
                                        name={'email'}
                                        label={'Email'}
                                        value={userForm.email}
                                        error={errors.email}
                                        formType={'createUser'}
                                        triggerFunction={(e) => handleInputChange(e)}
                                    />
                                    <CustomInput
                                        name={'userId'}
                                        label={'User Id'}
                                        value={userForm.userId}
                                        error={errors.userId}
                                        formType={'createUser'}
                                        triggerFunction={(e) => handleInputChange(e)}
                                    />
                                    <CustomInput
                                        name={'password'}
                                        label={'Password'}
                                        value={userForm.password}
                                        error={errors.password}
                                        formType={'createUser'}
                                        triggerFunction={(e) => handleInputChange(e)}
                                    />
                                </div>
                                <h2>Categories</h2>
                                <span style={{ color: "red", fontSize: "16px" }}>{errors['category']}</span>
                                <div>
                                    <h3>Job Provider</h3>
                                    <div>
                                        {[
                                            { name: 'employer', label: 'Employer' },
                                            { name: 'jobs', label: 'Jobs' },
                                            { name: 'client', label: 'Client' },
                                            { name: 'requirements', label: 'Requirements' }
                                        ].map(({ name, label, onChange }) => (
                                            <div key={name}>
                                                <input
                                                    type="checkbox"
                                                    name={name}
                                                    value={name}
                                                    id={name}
                                                    onChange={(e) => handleJobChange(e)}
                                                    checked={userForm.jobs.includes(name)}
                                                />
                                                <label htmlFor={name}>{label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <h3>Cook</h3>
                                    <div>
                                        {[
                                            { name: 'chef', label: 'Chef' },
                                            { name: 'houseCook', label: 'House Cook' },
                                            { name: 'partyCook', label: 'Party Cook', id: 'party' },
                                            { name: 'catering', label: 'Catering' }
                                        ].map(({ name, label, id }) => (
                                            <div key={name}>
                                                <input
                                                    type="checkbox"
                                                    name={name}
                                                    value={name}
                                                    id={id || name}
                                                    onChange={(e) => handleCookChange(e)}
                                                    checked={userForm.cooks.includes(name)}
                                                />
                                                <label htmlFor={id || name}>{label}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <button onClick={(e) => handleReset(e)} className={`btn gray_btn`}>Reset</button>
                                    <button onClick={(e) => handleCreate(e)} className={`btn green_btn`}>Create</button>
                                </div>
                            </form>
                        </div>
                    )
                }
            </div>
            {openEditModal && <EditUserModal data={data[rowSelected]} triggerFunction={() => setOpenEditModal(false)} />}
            {openResetPwModal && <UserResetPwModal id={data[rowSelected].id} triggerFunction={() => setOpenResetPwModal(false)} />}
        </div>
    )
}
export default Users