import React, { useContext, useState } from 'react'
import css from './UserResetPwModal.module.css'
import CloseBtn from './ui/CloseBtn';
import CustomInput from './ui/CustomInput';
import { BASE_URL } from '../constants/urls';
import { MyContext } from '../context/AppContext';

function UserResetPwModal({ id, triggerFunction }) {

    const context = useContext(MyContext)

    const [cred, setCred] = useState({ pw: "", cpw: "" })
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setErrors({ ...errors, [name]: '' })
        setCred({ ...cred, [name]: value })
    }

    async function handleReset() {
        if (cred.pw !== cred.cpw) {
            setErrors({ ...errors, "cpw": "Password does not match" })
            return
        }
        try {
            const response = await fetch(`${BASE_URL}/crm/users/${id}/password`, {
                method: "PUT",
                headers: context.headers,
                body: JSON.stringify({ password: cred.pw, updatedBy: context.headers.userId })
            })
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (response.ok) {
                alert("Password has been changed")
                window.location.reload()
            }
            else {
                alert("Some error happend")
            }
        } catch (error) {
            alert(error)
        }
    }

    return (
        <div className={css.container_1}>
            <div>
                <h2>Reset Password</h2>
                <CloseBtn triggerFunction={triggerFunction} />
            </div>
            <div>
                <CustomInput
                    label={'Password'}
                    name={'pw'}
                    triggerFunction={(e) => handleInputChange(e)}
                />
                <CustomInput
                    label={'Confirm Password'}
                    name={'cpw'}
                    error={errors['cpw']}
                    triggerFunction={(e) => handleInputChange(e)}
                />
            </div>
            <button className='btn green_btn' onClick={handleReset}>Reset Password</button>
        </div>
    )
}

export default UserResetPwModal
