import React, { useContext, useState } from 'react'
import css from './EditUserModal.module.css'
import CloseBtn from './ui/CloseBtn';
import CustomInput from './ui/CustomInput';
import { MyContext } from '../context/AppContext'
import { BASE_URL } from '../constants/urls';

function EditUserModal({ data, triggerFunction }) {
    const context = useContext(MyContext)

    const [userForm, setUserForm] = useState({
        userId: data.id,
        uname: data.name || '',
        access: data.accessLevel || '',
        email: data.email || '',
        jobs: data.jobs || [],
        cooks: data.cooks || []
    })

    const [errors, setErrors] = useState()

    async function handleUpdate(e) {
        e.preventDefault()
        const body = {
            name: userForm.uname,
            email: userForm.email,
            role: userForm.access,
            categories: [...userForm.jobs, ...userForm.cooks],
            updatedBy: "admin@gmail.com"
        }
        try {
            const response = await fetch(`${BASE_URL}/crm/users/${userForm.userId}`, {
                method: "PUT",
                headers: context.headers,
                body: JSON.stringify(body)
            })
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (data.success) {
                alert("Data has been updated")
                window.location.reload()
            }
        } catch (error) {
            alert(error)
        }
        console.log(userForm);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setErrors({ ...errors, [name]: '' })
        setUserForm({ ...userForm, [name]: value })
    }

    const handleJobChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setUserForm((userForm) => ({ ...userForm, jobs: [...userForm.jobs, value] }));
        } else {
            setUserForm((userForm) => ({ ...userForm, jobs: userForm.jobs.filter((job) => job !== value) }));
        }
    };

    const handleCookChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setUserForm((userForm) => ({ ...userForm, cooks: [...userForm.cooks, value] }));
        } else {
            setUserForm((userForm) => ({ ...userForm, cooks: userForm.cooks.filter((cook) => cook !== value) }));
        }
    }

    return (
        <div className={css.container_1}>
            <div>
                <h1>Edit User</h1>
                <CloseBtn triggerFunction={triggerFunction} />
            </div>
            <div className={css.createModal}>
                <form>
                    <div>
                        <CustomInput
                            name={'uname'}
                            label={'Name'}
                            value={userForm?.uname}
                            error={errors?.uname}
                            formType={'createUser'}
                            triggerFunction={(e) => handleInputChange(e)}
                        />
                        <div>
                            <label htmlFor="access"><h3>Access Level</h3></label>
                            <select
                                name="access"
                                id="access"
                                value={userForm?.access || ""}
                                onChange={(e) => handleInputChange(e)}
                            >
                                <option value="manager">Manager</option>
                                <option value="executive">Executive</option>
                                <option value="associate">Associate</option>
                            </select>
                        </div>
                        <CustomInput
                            name={'email'}
                            label={'Email'}
                            value={userForm?.email}
                            error={errors?.email}
                            formType={'createUser'}
                            triggerFunction={(e) => handleInputChange(e)}
                        />
                    </div>
                    <h3>Categories</h3>
                    <div>
                        <h3>Job Provider</h3>
                        <div>
                            {[
                                { name: 'employer', label: 'Employer' },
                                { name: 'jobs', label: 'Jobs' },
                                { name: 'client', label: 'Client' },
                                { name: 'requirements', label: 'Requirements' }
                            ].map(({ name, label }) => (
                                <div key={name}>
                                    <input
                                        type="checkbox"
                                        name={name}
                                        value={name}
                                        id={name}
                                        onChange={(e) => handleJobChange(e)}
                                        checked={userForm.jobs.includes(name)}
                                    />
                                    <label htmlFor={name}>{label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h3>Cook</h3>
                        <div>
                            {[
                                { name: 'chef', label: 'Chef' },
                                { name: 'houseCook', label: 'House Cook' },
                                { name: 'partyCook', label: 'Party Cook', id: 'party' },
                                { name: 'catering', label: 'Catering' }
                            ].map(({ name, label, id }) => (
                                <div key={name}>
                                    <input
                                        type="checkbox"
                                        name={name}
                                        value={name}
                                        id={id || name}
                                        onChange={(e) => handleCookChange(e)}
                                        checked={userForm.cooks.includes(name)}
                                    />
                                    <label htmlFor={id || name}>{label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <button onClick={(e) => handleUpdate(e)} className={`btn green_btn`}>Update</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditUserModal
