import React from "react";
import { Route, Routes } from "react-router-dom";
import './global.css';
import DashBoard from "./pages/DashBoard";
import ChefForm from "./pages/ChefForm";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import Users from "./pages/Users";
import PrivateRoute from "./components/PrivateRoute";
import Client from "./pages/Client";
import HouseCook from "./pages/HouseCook";
import Tasks from "./pages/Tasks"
import Employer from "./pages/Employer";
import Settings from './pages/Settings'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
        <Route path="dashboard" element={<DashBoard />} />
        <Route path="chef" element={<ChefForm />} />
        <Route path="users" element={<Users />} />
        <Route path="client" element={<Client />} />
        <Route path="houseCook" element={<HouseCook />} />
        <Route path="tasks" element={<Tasks/>} />
        <Route path="employer" element={<Employer/>} />
        <Route path="settings" element={<Settings/>} />
      </Route>
    </Routes>
  );
}

export default App;
