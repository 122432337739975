import React, { useContext, useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";
import './CuisineSelector.css'
import { MyContext } from "../../context/AppContext";

const CuisineSelector = ({ selected, setSelected }) => {

    const context = useContext(MyContext)
    const cuisines = context.cuisines

    const [showCuisines, setShowCuisines] = useState(cuisines);
    const [isActive, setIsActive] = useState(false);

    function clearInput() {
        document.querySelector('input[name="cuisines"]').value = '';
    }

    function handleKeyUp(e) {
        const { value } = e.target;
        if (!value.trim()) {
            setShowCuisines([]);
            return;
        }
        const temp = cuisines.filter((item) =>
            item.toLowerCase().includes(value.toLowerCase())
        );
        setShowCuisines(temp);
    };

    function handleCuisineClick(value) {
        if (!selected.includes(value)) {
            setSelected((prevSelected) => [...prevSelected, value]);
            setShowCuisines((prevCuisines) => prevCuisines.filter((item) => item !== value));
        }
        clearInput()
    };

    function handleOnBlur(e) {
        if (!e.currentTarget.contains(e.relatedTarget)) setIsActive(false);
        e.target.value = ''
        clearInput()
    }

    function handleOnFocus() {
        setIsActive(true);
        const temp = cuisines.filter((item) => !selected.includes(item));
        setShowCuisines(temp);
    }

    function handleOnRemove() {
        setIsActive(false);
    }

    return (
        <div
            onFocus={() => handleOnFocus()}
            onBlur={(e) => handleOnBlur(e)}
            className="cuisine-container"
        >
            <label>Cuisine</label>
            <TagsInput
                value={selected}
                onChange={setSelected}
                onKeyUp={handleKeyUp}
                onRemoved={handleOnRemove}
                name="cuisines"
            />
            <div className="cuisines-sub-container">
                {isActive &&
                    showCuisines.map((field, idx) => (
                        <div
                            key={idx}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                handleCuisineClick(field);
                            }}
                        >
                            {field}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default CuisineSelector;
