import React, { useContext, useEffect, useState } from "react";
import css from "./CollapseTable.module.css";
import { BASE_URL } from "../constants/urls";
import axios from "axios";
import CuisineSelector from "./ui/CuisineSelector";
import RemarksModal from "./RemarksModal";
import { MyContext } from "../context/AppContext";

const CollapseTable = (props) => {

    const context = useContext(MyContext)

    const [selectedRow, setSelectedRow] = useState(null);
    const [clickedBtn, setClickedBtn] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [selected, setSelected] = useState([])
    const [isLeadUpdated, setIsLeadUpdated] = useState(false)
    const [openRemark, setOpenRemark] = useState(false)
    const [leadForm, setLeadForm] = useState({
        "name": "",
        "contact": 0,
        "category": "chef",
        "roles": ['chef'],
        "cuisines": [],
        "salary": 0,
        "city": "",
        "state": "",
        "qualification": "",
        "relocation": false,
        "sharedFor": "",
        "email": "",
        "designation": "",
        "updatedBy": "",
        "experience": "",
        "remarks": []
    })

    useEffect(() => {
        const a = props?.data[selectedRow]?.cuisine;
        if (JSON.stringify(a?.slice().sort()) !== JSON.stringify(selected?.slice().sort())) {
            setIsLeadUpdated(true);
            setLeadForm({ ...leadForm, cuisines: selected })
        } else {
            setIsLeadUpdated(false);
        }
        // eslint-disable-next-line
    }, [selectedRow, selected]);

    const TableRow = ({ row, index, handleRowClick }) => {
        const tableFields = [
            { label: "Name", value: row.name },
            { label: "Contact Number", value: row.contactNumber },
            { label: "Email", value: row.email },
            { label: "City", value: row.city },
            { label: "State", value: row.state },
            { label: "Cuisine", value: row.cuisine.join(", ") },
            { label: "Qualification", value: row.qualification },
            { label: "Salary", value: row.salary },
        ];
        return (
            <tr onClick={() => handleRowClick(index)} style={{ cursor: "pointer" }}>
                {tableFields.map((field, idx) => (
                    <td key={idx} data-label={field.label} title={field.value}>
                        <div className={css.data_cell}>{field.value}</div>
                    </td>
                ))}
            </tr>
        );
    };

    const handleRowClick = (index) => {
        setSelectedRow(selectedRow === index ? null : index);
        setSelected(props?.data[index]?.cuisine);
        setIsLeadUpdated(false)
        setLeadForm({
            ...leadForm,
            name: props?.data[index]?.name || "",
            contact: props?.data[index]?.contactNumber || 0,
            category: props?.data[index]?.category,
            roles: props?.data[index]?.role || ['chef'],
            cuisines: props?.data[index]?.cuisine || [],
            salary: props?.data[index]?.salary || 0,
            city: props?.data[index]?.city || "",
            state: props?.data[index]?.state || "",
            qualification: props?.data[index]?.qualification || "",
            relocation: props?.data[index]?.relocation || false,
            sharedFor: props?.data[index]?.sharedFor || "",
            email: props?.data[index]?.email || "",
            designation: props?.data[index]?.designation || "",
            updatedBy: props?.data[index]?.updatedBy || "",
            experience: props?.data[index]?.experience || "",
            remarks: props?.data[index]?.remarks || [],
        });
    };

    const handleDeleteLead = async (id) => {
        const check = window.confirm("Do you want to delete this Lead?")
        if (check === false) {
            return
        }
        setClickedBtn(2)
        setIsLoading(true)
        try {
            const responce = await axios.delete(`${BASE_URL}/crm/leads/${id}`, {
                headers: context.headers
            })
            if (responce?.data) {
                alert("Lead has been deleted")
                window.location.reload()
            }
        }
        catch (error) { alert(error.message); }
        setClickedBtn(0)
        setIsLoading(false)
    }

    const validateInput = (data) => {
        return true
    }

    const handelSaveChanges = async (idx) => {
        setIsLoading(true);
        setClickedBtn(1)
        if (validateInput(leadForm) === false) {
            setIsLoading(false);
            return
        }
        const body = {
            "name": leadForm.name,
            "contact": leadForm.contact,
            "category": leadForm.category || "chef",
            "roles": leadForm.roles,
            "cuisines": typeof leadForm?.cuisines === 'string' ? leadForm.cuisines.split(',') : leadForm.cuisines,
            "salary": +leadForm.salary || 1,
            "city": leadForm.city,
            "state": leadForm.state,
            "qualification": leadForm.qualification,
            "relocation": leadForm.relocation || false,
            "sharedFor": leadForm.sharedFor,
            "email": leadForm.email,
            "designation": leadForm.designation,
            "updatedBy": localStorage.getItem("userId") || 'abc@gmail.com',
            "experience": `${leadForm.experience}`,
            "remarks": leadForm.remarks
        }
        const url = `${BASE_URL}/crm/leads/${props.data[idx]._id}/`
        try {
            const response = await fetch(url, {
                method: "PUT",
                body: JSON.stringify(body),
                headers: context.headers
            })
            const data = await response.json()
            if (data.statusCode === 401) { context.logout(); return }
            if (data?.success) {
                alert('Changes Saved');
                props.data[idx] = {
                    "_id": props.data[idx]._id,
                    "name": leadForm.name,
                    "contactNumber": leadForm.contact,
                    "email": leadForm.email,
                    "city": leadForm.city,
                    "state": leadForm.state,
                    "cuisine": leadForm.cuisines,
                    "qualification": leadForm.qualification,
                    "salary": leadForm.salary,
                    "role": leadForm.roles,
                    "experience": leadForm.experience,
                    "designation": leadForm.designation,
                    "remarks": leadForm.remarks,
                    "sharedFor": leadForm.sharedFor
                }
                setIsLoading(false);
                setClickedBtn(0);
                setIsLeadUpdated(false);
                return
            }
            else {
                alert(data.message)
            }
        }
        catch (error) { alert(error.message); }
        setIsLoading(false);
        setClickedBtn(0)
    }

    const handleRemarks = () => {
        setOpenRemark(!openRemark)
    }

    const handleOnChange = (e) => {
        const { value, name } = e.target
        setLeadForm({ ...leadForm, [name]: value })
        setIsLeadUpdated(true)
    }

    const handleRoleChange = (e) => {
        const { checked, name } = e.target;
        let temp = [...leadForm.roles];
        const val = name.replace('-', ' ');
        if (checked && !temp.includes(val)) { temp.push(val); }
        if (!checked && temp.includes(val)) { temp = temp.filter((item) => item !== val); }
        setLeadForm({ ...leadForm, roles: temp });
        setIsLeadUpdated(true)
    };

    return (
        <>
            {
                props.data.length === 0 ? <div className={css.no_data_container}>No data found</div> :
                    <table className={css.collapseTable}>
                        <thead>
                            <tr>
                                {["Name", "Contact Number", "Email", "City", "State", "Cuisine", "Qualification", "Salary"].map((header) => (<th key={header}>{header}</th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {props.data.map((row, index) => (
                                <React.Fragment key={index}>
                                    <TableRow key={index} row={row} index={index} handleRowClick={handleRowClick} />
                                    {selectedRow === index && (
                                        <tr className={css.details_row}>
                                            <td colSpan={8}>
                                                <div className={css.details_container}>
                                                    <div>
                                                        <button className={`blue_btn btn`} onClick={() => handelSaveChanges(index)} disabled={!isLeadUpdated}>{
                                                            clickedBtn === 1 && isLoading === true ? 'Loading...' : 'Update'
                                                        }</button>
                                                        <button className={`red_btn btn`} onClick={() => handleDeleteLead(row._id)}>{
                                                            clickedBtn === 2 && isLoading === true ? 'Loading...' : 'Delete'
                                                        }</button>
                                                        <button className={`blue_btn btn`} onClick={() => handleRemarks(row.remarks)}>Remarks</button>
                                                    </div>
                                                    <h3>Basic Details</h3>
                                                    <div>
                                                        <div>
                                                            <div>Name</div>
                                                            <input
                                                                type="text"
                                                                name="name"
                                                                defaultValue={row.name}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>Number</div>
                                                            <input
                                                                type="tel"
                                                                name="contact"
                                                                defaultValue={row.contactNumber}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>Email</div>
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                defaultValue={row.email}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>City</div>
                                                            <input
                                                                type="text"
                                                                name="city"
                                                                defaultValue={row.city}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>State</div>
                                                            <input
                                                                type="text"
                                                                name="state"
                                                                defaultValue={row.state}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <div className={css.role_container}>
                                                            <div>Roles</div>
                                                            <div>
                                                                Party Cook
                                                                <input
                                                                    type="checkbox"
                                                                    name="party-cook"
                                                                    defaultChecked={row.role.includes('party cook')}
                                                                    onChange={(e) => handleRoleChange(e)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h3>Professional Details</h3>
                                                    <div>
                                                        <div>
                                                            <div>Salary</div>
                                                            <input
                                                                type="number"
                                                                name="salary"
                                                                defaultValue={row.salary}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>Experience</div>
                                                            <input
                                                                type="number"
                                                                name="experience"
                                                                defaultValue={row.experience}
                                                                onChange={(e) => handleOnChange(e)}
                                                                min={0}
                                                                max={50}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>Designation</div>
                                                            <input
                                                                type="text"
                                                                name="designation"
                                                                defaultValue={row.designation}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>Qualification</div>
                                                            <input
                                                                type="text"
                                                                name="qualification"
                                                                defaultValue={row.qualification}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div>Shared For</div>
                                                            <input
                                                                type="text"
                                                                name="sharedFor"
                                                                defaultValue={row.sharedFor}
                                                                onChange={(e) => handleOnChange(e)}
                                                            />
                                                        </div>
                                                        <CuisineSelector selected={selected} setSelected={setSelected} />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
            }
            {
                openRemark === false ? <></> : <RemarksModal data={props.data[selectedRow].remarks} handelOpenRemarks={handleRemarks} leadId={props.data[selectedRow]._id} />
            }
        </>
    );
};

export default CollapseTable;