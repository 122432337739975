import React, { useState } from 'react'
import css from './Login.module.css'
import { BASE_URL } from '../constants/urls'
import { useNavigate } from 'react-router-dom'

function Login() {

    const navigate = useNavigate()
    document.title = 'CookandChef CRM | Login'

    const [cred, setCred] = useState({ uname: "", pw: "", opt: "", cpw: "" })
    const [showPw, setShowPw] = useState({ btn1: false, btn2: false, btn3: false })
    const [disabled, setDisabled] = useState({ btn1: false, btn2: false, btn3: false })
    const [forgotPw, setForgotPw] = useState(false)
    const [optSent, setOtpSent] = useState(false)
    const [validOtp, setValidOtp] = useState(false)

    function handleInputChange(e) {
        const { name, value } = e.target
        setCred({ ...cred, [name]: value })
    }

    function isValid() {
        if (cred.uname.length <= 0 || cred.pw.length <= 0) { return false }
        return true
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (isValid() === false) { return }
        try {
            const response = await fetch(`${BASE_URL}/crm/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ userId: cred.uname, password: cred.pw }),
            });
            const data = await response.json();
            if (!response.ok) {
                alert(data.message)
            }
            else {
                localStorage.setItem("auth-token", `Bearer ${data?.data?.token}`);
                localStorage.setItem("userRole", data?.data?.userRole)
                localStorage.setItem("userId", data?.data?.userId)
                localStorage.setItem("userName", data?.data?.userName)
                navigate('/dashboard')
            }
            window.location.reload()
        } catch (error) {
            console.error("Error occurred during login:", error);
            alert(error.message);
        }
    }

    function handelShowPw(v, e) {
        if (e) e.preventDefault()
        setShowPw({ ...showPw, [`btn${v}`]: !showPw[`btn${v}`] })
    }

    function handleBackBtn() {
        setCred({ uname: "", pw: "", opt: "", cpw: "" })
        setForgotPw(false)
        setOtpSent(false)
        setValidOtp(false)
        setDisabled({ btn1: false, btn2: false, btn3: false })
    }

    async function handleSubmitUname() {
        try {
            // const response=await fetch('',{method:"POST",body:JSON.stringify({uname:cred.uname})})            
            setOtpSent(true)
            setDisabled({ ...disabled, btn1: true })
        } catch (error) {
            console.log(error);
        }
    }

    async function handleSubmitOtp() {
        try {
            // const response=await fetch('',{method:"POST",body:JSON.stringify({opt:cred.opt})})            
            setValidOtp(true)
            setDisabled({ ...disabled, btn2: true })
        } catch (error) {
            console.log(error);
        }
    }

    async function handleSubmitReset() {
        if (cred.pw !== cred.cpw) {
            alert("Passwords do not match")
            return
        }
        try {
            // const response=await fetch('',{method:"POST",body:JSON.stringify({})})            
        } catch (error) {
            console.log(error);
        }
        handleBackBtn()
    }

    return (
        <div className={css.container_1}>
            <div className={css.container_2}>
                <div className={css.container_2_1}></div>
            </div>
            <div className={css.container_8}>
                <h2>CookandChef CRM</h2>
            </div>
            <div className={css.container_3}>
                <div className={css.container_4}>
                    <div className={css.container_4_1}></div>
                </div>
                <div className={css.container_5}>
                    <div className={css.container_5_1}>
                        <h1>Login</h1>
                        {/* <div className={css.container_7}>
                            <h3>User Type</h3>
                            <select name="" id="" defaultValue={'user'} onChange={(e) => handleTypeChange(e)}>
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                            </select>
                        </div> */}
                        <form className={css.container_6}>
                            <input
                                type="text"
                                name="uname"
                                onChange={handleInputChange}
                                placeholder="Username"
                                required
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Prevent form submission
                                        document.querySelector("input[name='pw']").focus(); // Move focus to the password input
                                    }
                                }}
                            />
                            <div className={css.container_6_1}>
                                <input
                                    type={!showPw.btn1 ? 'password' : 'text'}
                                    name="pw"
                                    onChange={handleInputChange}
                                    placeholder="Password"
                                    required
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault(); // Prevent form submission
                                            document.querySelector("button[type='submit']").focus(); // Move focus to the Login button
                                        }
                                    }}
                                />
                                <button type="button" onClick={(e) => handelShowPw(1, e)}>
                                    {!showPw.btn1 ? 'Show' : 'Hide'}
                                </button>
                            </div>
                            <button type="button" onClick={() => setForgotPw(true)}>
                                Forgot Password
                            </button>
                            <button type="submit" onClick={handleSubmit}>
                                Login
                            </button>
                        </form>
                    </div>
                    {
                        !forgotPw ? <></> :
                            <div className={css.container_5_2}>
                                <div>
                                    <h1>Forgot Password</h1>
                                    <button onClick={handleBackBtn}>Back</button>
                                    <div>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder='User name'
                                                name='uname'
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                            <button onClick={handleSubmitUname} disabled={disabled.btn1}>Submit</button>
                                        </div>
                                        {
                                            optSent === false ? <></> :
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="otp"
                                                        id="otp"
                                                        placeholder='OTP'
                                                        onChange={(e) => handleInputChange(e)}
                                                    />
                                                    <button onClick={handleSubmitOtp} disabled={disabled.btn2}>Submit</button>
                                                </div>
                                        }
                                    </div>
                                    {
                                        validOtp === false ? <></> :
                                            <div>
                                                <div>
                                                    <div className={css.container_6_1}>
                                                        <input
                                                            type={!showPw.btn2 ? 'password' : 'text'}
                                                            name='pw'
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder='New Password'
                                                            required
                                                        />
                                                        <button onClick={() => handelShowPw(2)}>{!showPw.btn2 ? 'Show' : 'Hide'}</button>
                                                    </div>
                                                    <div className={css.container_6_1}>
                                                        <input
                                                            type={!showPw.btn3 ? 'password' : 'text'}
                                                            name='cpw'
                                                            onChange={(e) => handleInputChange(e)}
                                                            placeholder='Confirm Password'
                                                            required
                                                        />
                                                        <button onClick={() => handelShowPw(3)}>{!showPw.btn3 ? 'Show' : 'Hide'}</button>
                                                    </div>
                                                </div>
                                                <button onClick={handleSubmitReset}>Submit</button>
                                            </div>
                                    }
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Login
