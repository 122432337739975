import React, { useContext, useEffect, useState } from 'react';
import './ChefForm.css';
import MultiRangeSlider from '../components/ui/MultiRangeSlider';
import CollapseTable from '../components/CollapseTable';
import Pagination from '../components/ui/Pagination';
import CustomInput from '../components/ui/CustomInput';
import { BASE_URL } from "../constants/urls";
import CuisineSelector from '../components/ui/CuisineSelector';
import LeadFormModal from '../components/LeadFormModal';
import { MyContext } from '../context/AppContext';

function ChefForm() {

    const context = useContext(MyContext)
    document.title = 'CookandChef CRM | Chef Form'

    const initialFilters = {
        city_1: '', state_1: '', cuisine_1: '', nameEmailContact: '', qualification_1: '', salaryRange: [0, 100000]
    }

    const filterOptions = [
        { label: 'City', name: 'city_1' },
        { label: 'State', name: 'state_1' },
        { label: 'Qualification', name: 'qualification_1' },
        { label: "Name / Email / Contact", name: 'nameEmailContact' },
    ]

    const maxSalary = 100000

    const [filters, setFilters] = useState(initialFilters);
    const [filterReset, setFilterReset] = useState(false)
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState();
    const [clickedBtn, setClickedBtn] = useState(0)
    const [selectedDataPoint, setSelectedDataPoint] = useState(null);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [selected_1, setSelected_1] = useState([]);
    const itemsPerPage = 7;
    const totalPages = Math.ceil(totalItems / itemsPerPage)

    const formatResponce = (backendResponse) => {
        const transformedData = backendResponse.data.leads.map((lead) => ({
            _id: lead._id,
            name: lead.name,
            contactNumber: lead.contact,
            email: lead.email,
            city: lead.city,
            state: lead.state,
            cuisine: lead?.cuisines || [],
            qualification: lead.qualification,
            salary: lead.salary === 1 ? "" : lead.salary,
            role: lead?.roles || [],
            experience: lead?.experience,
            designation: lead.designation,
            remarks: lead?.remarks || ["abcd"],
            sharedFor: lead.sharedFor,
        }));
        return transformedData
    }

    const handleInputChange = (e, formType) => {
        const { name, value } = e.target;
        setErrors({ ...errors, [name]: '' })
        if (formType === 'filters') {
            setFilters({ ...filters, [name]: value });
        }
    };

    const handleReset = () => {
        setFilters(initialFilters);
        setFilterReset(true)
        setErrors({})
        setFilterData()
        setSelectedDataPoint(null)
        setSelected_1([])
    };

    const validateSearch = () => {
        var temp = {};
        if (/\d/.test(filters?.state_1)) {
            temp['state_1'] = 'Invalid State';
        }
        setErrors(temp);
        return Object.keys(temp).length === 0;
    };

    const getLeads = async (idx) => {
        const offset = idx ? (idx - 1) * itemsPerPage : 0;
        if (idx) setCurrentPage(idx - 1);
        setLoading(true);
        setClickedBtn(1);
        setFilterData();
        setSelectedDataPoint(null);

        if (!validateSearch()) {
            setLoading(false);
            return;
        }

        setErrors({});

        const filterBody = {
            category: "chef",
            ...(filters.nameEmailContact && {
                name: filters.nameEmailContact,
                email: filters.nameEmailContact,
                contact: filters.nameEmailContact,
            }),
            ...(filters.cuisine_1.length && { cuisines: filters.cuisine_1 }),
            ...(filters.city_1 && { city: filters.city_1 }),
            ...(filters.state_1 && { state: filters.state_1 }),
            ...(filters.qualification_1 && { qualification: filters.qualification_1 }),
            ...(filters.salaryRange && {
                salary: {
                    max: filters.salaryRange[1] || maxSalary,
                    min: filters.salaryRange[0] || 0,
                },
            }),
        };
        if (filters.salaryRange[1] === maxSalary) {
            filterBody['salary'] = { min: filters.salaryRange[0] || 0 }
        }
        try {
            const body = { offset, limit: itemsPerPage, filters: filterBody };

            const response = await fetch(`${BASE_URL}/crm/getLeads`, {
                method: "POST",
                headers: context.headers,
                body: JSON.stringify(body),
            });
            const data = await response.json();
            if (data.statusCode === 401) { context.logout(); return }
            setTotalItems(data.data.count);
            setFilterData(formatResponce(data));
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
            setClickedBtn(0);
        }
    };

    const handelRowClick = (idx) => {
        if (selectedDataPoint === idx) {
            setSelectedDataPoint(null);
            setTimeout(() => setSelectedDataPoint(idx), 0);
        } else { setSelectedDataPoint(idx); }
    }

    useEffect(() => {
        setFilters({ ...filters, 'cuisine_1': selected_1 });
        // eslint-disable-next-line
    }, [selected_1])

    useEffect(() => {
        const keys = Object.keys(errors)
        if (keys.length) {
            document.querySelector(`[name="${keys[0]}"]`).scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [errors])

    return (
        <>
            <div className="chef-form">
                <div className="filters-container">
                    <h1 className="title">Chef</h1>
                    <h2 className='sub-title-1'>Filters</h2>
                    <div className="filters">
                        {filterOptions.map((field, index) => (
                            <CustomInput
                                key={index}
                                name={field.name}
                                label={field.label}
                                value={filters[field.name]}
                                error={errors[field.name]}
                                formType={"filters"}
                                triggerFunction={handleInputChange}
                            />
                        ))}
                        <CuisineSelector selected={selected_1} setSelected={setSelected_1} />
                        <div className="input-group">
                            <label htmlFor="salaryRange">Salary Range</label>
                            <MultiRangeSlider min={0} max={maxSalary} step={100} reset={filterReset}
                                onChange={({ min, max }) => {
                                    setFilters({ ...filters, salaryRange: [min, max] })
                                    setFilterReset(false)
                                }} />
                        </div>
                    </div>
                    <div className="button-group">
                        <button className='reset-btn btn' onClick={handleReset}>Reset</button>
                        <button id="lead-search-btn" className="search-btn btn" onClick={() => getLeads()}>Search</button>
                    </div>
                </div>
                {
                    loading && clickedBtn === 1 ? <div className="filter-data-container">Loading....</div> : !loading && filterData ?
                        <>
                            {
                                totalPages <= 0 ? <></> :
                                    <Pagination triggerFunction={getLeads} currentPage={currentPage} totalPages={totalPages} />
                            }
                            <div className="filter-data-container">
                                <CollapseTable data={filterData} handelRowClick={handelRowClick} />
                            </div>
                        </>
                        : <></>
                }
                <LeadFormModal />
            </div>
        </>
    );
}

export default ChefForm;